import './footer.scss'
function Footer() {
    return(
        <div className="Footer">
            <div  className="footerImage" style={{height: '22px',backgroundImage: `url("logo2.png")`, backgroundSize: 'contain'}}></div>

            {/* &copy; 2022 */}
       
        </div>
    )
}

export default Footer;