export const backendLink = 'https://englishclassepsom.co.uk/backend';
export const backendImgLink = 'https://admin.englishclassepsom.co.uk/backend/uploads/';
export const backendImgLinkGallery = 'https://admin.englishclassepsom.co.uk/backend/';

/*

export const backendLink = 'https://englishclassepsom.co.uk/backend';
export const backendImgLink = 'https://admin.englishclassepsom.co.uk/backend/uploads/';
export const backendImgLinkGallery = 'https://admin.englishclassepsom.co.uk/backend/';


export const backendLink = 'http://localhost/backend';
export const backendImgLink = 'http://localhost/backend/uploads/';
export const backendImgLinkGallery = 'http://localhost/backend/';
*/

